import produce from 'immer'

import {
  CHANGE_SHOW_ALL_FIELDS,
  UPDATE_CREATE_MODE,
  UPDATE_EVENTS_ACTIVE_BRANCH,
} from '../actions/SettingsActions'

const initialState = {
  __persist: true,
  eventForm: {
    isShowAllFields: true,
    create_mode: 'excursion',
  },
  eventsList: {
    active_branch: null,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREATE_MODE:
      return produce(state, draftState => {
        draftState.eventForm.create_mode = action.payload.value
      })
    case CHANGE_SHOW_ALL_FIELDS:
      return produce(state, draftState => {
        draftState.eventForm.isShowAllFields = action.payload.value
      })
    case UPDATE_EVENTS_ACTIVE_BRANCH:
      return produce(state, draftState => {
        draftState.eventsList.active_branch = action.payload.value
      })
    default:
      return state
  }
}
