export const SET_FIELD_FOR_CASHIER = 'CASHIER/SET_FIELD_FOR_CASHIER'

export function setFileldForCashier(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_CASHIER,
      payload: {
        field,
        value,
      },
    })
  }
}

export const TICKETS_LOAD_REQUEST = 'CASHIER/TICKETS_LOAD_REQUEST'
export const TICKETS_LOAD_SUCCESS = 'CASHIER/TICKETS_LOAD_SUCCESS'

export function getTicketsList(pageNumber = 1, filterForm = null) {
  return dispatch => {
    let additionalParams = {
      sort: 'title',
      sort_type: 'asc'
    }

    if (filterForm && filterForm.place) {
      additionalParams.place = [parseInt(filterForm.place)]
    }

    additionalParams.available_for_sale = 1
    additionalParams.is_offline = 1

    dispatch({
      types: [TICKETS_LOAD_REQUEST, TICKETS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/ticket/get-tickets-list',
          params: {
            page: pageNumber,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const SET_CATEGORIES_FOR_TICKET = 'CASHIER/SET_CATEGORIES_FOR_TICKET'

export function setCategoriesForTicket(ticket_id) {
  return dispatch => {
    dispatch({
      type: SET_CATEGORIES_FOR_TICKET,
      payload: {
        ticket_id,
      },
    })
  }
}

export const UPDATE_CATEGORY_NUMBER = 'CASHIER/UPDATE_CATEGORY_NUMBER'

export function updateCategoryNumber(id, number) {
  return dispatch => {
    dispatch({
      type: UPDATE_CATEGORY_NUMBER,
      payload: {
        id,
        number,
      },
    })
  }
}

export const SEND_MANUAL_REQUEST = 'CASHIER/SEND_MANUAL_REQUEST'
export const SEND_MANUAL_SUCCESS = 'CASHIER/SEND_MANUAL_SUCCESS'

export function sendManualSales(formObj) {
  return dispatch => {
    dispatch({
      types: [SEND_MANUAL_REQUEST, SEND_MANUAL_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/cashier/send-manual-sales`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

export const UPLOAD_FILE = 'CASHIER/UPLOAD_FILE'

export function uploadFile(isLoading = false, data = null) {
  return dispatch => {
    dispatch({
      type: UPLOAD_FILE,
      payload: {
        isLoading,
        data,
      },
    })
  }
}

export const SET_TRANSACTION_NUMBER = 'CASHIER/SET_TRANSACTION_NUMBER'

export function setTransactionNumber(terminalId, orderIndex = null, type = 'rrn', value = null) {
  return dispatch => {
    dispatch({
      type: SET_TRANSACTION_NUMBER,
      payload: {
        terminalId,
        orderIndex,
        type,
        value,
      },
    })
  }
}

export const SEND_OFFLINE_RRN_REQUEST = 'CASHIER/SEND_OFFLINE_RRN_REQUEST'
export const SEND_OFFLINE_RRN_SUCCESS = 'CASHIER/SEND_OFFLINE_RRN_SUCCESS'

export function sendOfflineRrn(orders) {
  return dispatch => {
    dispatch({
      types: [SEND_OFFLINE_RRN_REQUEST, SEND_OFFLINE_RRN_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/cashier/send-offline-rrn`,
          params: {
            orders,
          },
        },
      },
    })
  }
}

export const RESET_MESSAGE = 'CASHIER/RESET_MESSAGE'

export function resetMessage(section = 'unloading') {
  return dispatch => {
    dispatch({
      type: RESET_MESSAGE,
      payload: {
        section,
      },
    })
  }
}