import React, { PureComponent } from 'react'
import { Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { history } from '../../redux/configureStore'
import { Layout, Menu, message } from 'antd'

import NotFound from '../Pages/NotFound/NotFoundComponent'
import Tickets from '../Pages/Tickets/TicketsComponent'
import Subscriptions from '../Pages/Subscriptions/SubscriptionsComponent'
import Certificates from '../Pages/Certificates/CertificatesComponent'
import Report from '../Pages/Report/ReportComponent'
import Order from '../Pages/Order/OrderComponent'
import Cashier from '../Pages/Cashier/CashierComponent'

import { connect } from 'react-redux'
import { logOutUser } from '../../redux/actions/UserActions'

class MainLayout extends PureComponent {
  logOut(e) {
    e.preventDefault()
    this.props.logOutUser()
  }

  componentDidMount() {
    this.checkAuth()
  }

  componentDidUpdate() {
    if (this.props.server.error.status) {
      message.error(
        this.props.server.error.status + ': ' + this.props.server.error.text
      )
    }

    this.checkAuth()
  }

  checkAuth() {
    if (!this.props.user.authorized) {
      document.location = '/login'
    }
  }

  render() {
    const { user } = this.props
    const pathname = document.location.pathname

    if (!user.authorized) {
      return null
    }

    const { Header, Content, Footer } = Layout
    const date = new Date()

    let selectKey = '1'
    switch (pathname) {
      case '/login':
        selectKey = '1'
        break
      case '/':
        selectKey = '1'
        break
      case '/report':
        selectKey = '2'
        break
      case '/order':
        selectKey = '3'
        break
      case '/cashier':
        selectKey = '4'
        break
      case '/subscriptions':
        selectKey = '5'
        break
      case '/certificates':
        selectKey = '6'
        break
      default:
        selectKey = '0'
        break
    }

    return (
      <Layout>
        <Header className="Header">
          <div className="Header__userInfo">
            {user.attributes.name}
            <Link
              className="Header__exit-link"
              onClick={this.logOut.bind(this)}
              to=""
            >
              Выход
            </Link>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[selectKey]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1">
              <Link to="/">Билеты</Link>
            </Menu.Item>
            {process.env.REACT_APP_SHOW_SUBSCRIPTIONS === 'true' && (
              <Menu.Item key="5">
                <Link to="/subscriptions">Абонементы</Link>
              </Menu.Item>
            )}
            {process.env.REACT_APP_SHOW_CERTIFICATES === 'true' && (
              <Menu.Item key="6">
                <Link to="/certificates">Сертификаты</Link>
              </Menu.Item>
            )}
            <Menu.Item key="3">
              <Link to="/order">Возврат</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/report">Отчеты</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/cashier">Кассир</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content className="Content">
          <div className="Content__container">
            <Router history={history}>
              <Switch>
                <Redirect from="/login" to="/" />

                <Route exact path="/" component={Tickets} />
                <Route exact path="/report" component={Report} />
                <Route exact path="/order" component={Order} />
                <Route exact path="/cashier" component={Cashier} />
                <Route exact path="/subscriptions" component={Subscriptions} />
                <Route exact path="/certificates" component={Certificates} />

                <Route component={NotFound} />
              </Switch>
            </Router>
          </div>
        </Content>
        <Footer className="Footer">
          ©{date.getFullYear()},{' '}
          <a
            href="https://museum.systems"
            rel="noopener noreferrer"
            target="_blank"
          >
            «Музейные кодовые системы»
          </a>
        </Footer>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    server: store.server,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: () => dispatch(logOutUser()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout)
