import produce from 'immer'

import {
  SET_FIELD_FOR_ORDER,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  CLOSE_MODAL,
  REFUND_ORDER_REQUEST,
  REFUND_ORDER_SUCCESS,
  GET_ORDER_BY_SOLD_TICKET_ID_REQUEST,
  GET_ORDER_BY_SOLD_TICKET_ID_SUCCESS,

  GET_SUBSCRIPTION_ORDER_REQUEST,
  GET_SUBSCRIPTION_ORDER_SUCCESS,
  REFUND_SUBSCRIPTION_ORDER_REQUEST,
  REFUND_SUBSCRIPTION_ORDER_SUCCESS,
  GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_REQUEST,
  GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_SUCCESS,

  GET_CERTIFICATE_ORDER_REQUEST,
  GET_CERTIFICATE_ORDER_SUCCESS,
  REFUND_CERTIFICATE_ORDER_REQUEST,
  REFUND_CERTIFICATE_ORDER_SUCCESS,
  GET_ORDER_BY_SOLD_CERTIFICATE_ID_REQUEST,
  GET_ORDER_BY_SOLD_CERTIFICATE_ID_SUCCESS,
} from '../actions/OrderActions'

const initialState = {

  filterForm: {
    order_id: null,

    isLoadingOrder: false,
  },

  ticketForm: {
    ticket_id: null,

    isLoadingOrder: false,
  },

  subscriptionFilterForm: {
    order_id: null,

    isLoadingOrder: false,
  },

  subscriptionForm: {
    subscription_id: null,

    isLoadingOrder: false,
  },

  certificateFilterForm: {
    order_id: null,

    isLoadingOrder: false,
  },

  certificateForm: {
    certificate_id: null,

    isLoadingOrder: false,
  },

  modalData: {
    order_id: null,
    user_fio: null,
    user_email: null,
    user_phone: null,
    created_date: null,
    parent_ticket: null,
    status: null,
    tickets: [],

    isRefundingOrder: false,
    isOpen: false,
  },

  subscriptionModalData: {
    isRefundingOrder: false,
    isOpen: false,

    order: {
      id: null,
      user_fio: null,
      user_email: null,
      user_phone: null,
      created_date: null,
      status: null,
      parent_subscription: null,
      subscriptions: [],
    },
  },

  certificateModalData: {
    isRefundingOrder: false,
    isOpen: false,

    order: {
      id: null,
      status: null,
      user_fio: null,
      user_email: null,
      user_phone: null,
      created_date: null,
      price: null,
      remaining_price: null,
    },
  },

}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDER_BY_SOLD_TICKET_ID_REQUEST:
      return produce(state, draftState => {
        draftState.ticketForm.isLoadingOrder = true
      })
    case GET_ORDER_BY_SOLD_TICKET_ID_SUCCESS:
      return produce(state, draftState => {
        draftState.ticketForm.isLoadingOrder = false
        draftState.modalData.isOpen = true

        if (action.payload.data.id) {
          draftState.modalData = {
            ...draftState.modalData,
            order_id: action.payload.data.id,
            ...action.payload.data,
          }
        } else {
          draftState.modalData.order_id = null
        }
      })
    case CLOSE_MODAL:
      return produce(state, draftState => {
        draftState[action.payload.modal].isOpen = false
      })
    case GET_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.filterForm.isLoadingOrder = true
      })
    case GET_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.filterForm.isLoadingOrder = false
        draftState.modalData.isOpen = true

        if (action.payload.data.id) {
          draftState.modalData = {
            ...draftState.modalData,
            order_id: action.payload.data.id,
            ...action.payload.data,
          }
        } else {
          draftState.modalData.order_id = null
        }
      })
    case SET_FIELD_FOR_ORDER:
      return produce(state, draftState => {
        draftState[action.payload.form][action.payload.field] = action.payload.value
      })
    case REFUND_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.modalData.isRefundingOrder = true
      })
    case REFUND_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.modalData.isRefundingOrder = false

        if (action.payload.data.id) {
          draftState.modalData = {
            ...draftState.modalData,
            order_id: action.payload.data.id,
            ...action.payload.data,
          }
        } else {
          draftState.modalData.order_id = null
        }
      })

    case GET_SUBSCRIPTION_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.subscriptionFilterForm.isLoadingOrder = true
      })
    case GET_SUBSCRIPTION_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.subscriptionFilterForm.isLoadingOrder = false
        draftState.subscriptionModalData.isOpen = true

        if (action.payload.data.id) {
          draftState.subscriptionModalData.order = action.payload.data
        } else {
          draftState.subscriptionModalData.order.id = null
        }
      })
    case REFUND_SUBSCRIPTION_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.subscriptionModalData.isRefundingOrder = true
      })
    case REFUND_SUBSCRIPTION_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.subscriptionModalData.isRefundingOrder = false

        if (action.payload.data.id) {
          draftState.subscriptionModalData.order = action.payload.data
        } else {
          draftState.subscriptionModalData.order.id = null
        }
      })
    case GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_REQUEST:
      return produce(state, draftState => {
        draftState.subscriptionForm.isLoadingOrder = true
      })
    case GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_SUCCESS:
      return produce(state, draftState => {
        draftState.subscriptionForm.isLoadingOrder = false
        draftState.subscriptionModalData.isOpen = true

        if (action.payload.data.id) {
          draftState.subscriptionModalData.order = action.payload.data
        } else {
          draftState.subscriptionModalData.order.id = null
        }
      })

    case GET_CERTIFICATE_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.certificateFilterForm.isLoadingOrder = true
      })
    case GET_CERTIFICATE_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.certificateFilterForm.isLoadingOrder = false
        draftState.certificateModalData.isOpen = true

        if (action.payload.data.id) {
          draftState.certificateModalData.order = action.payload.data
        } else {
          draftState.certificateModalData.order.id = null
        }
      })
    case REFUND_CERTIFICATE_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.certificateModalData.isRefundingOrder = true
      })
    case REFUND_CERTIFICATE_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.certificateModalData.isRefundingOrder = false

        if (action.payload.data.id) {
          draftState.certificateModalData.order = action.payload.data
        } else {
          draftState.certificateModalData.order.id = null
        }
      })
    case GET_ORDER_BY_SOLD_CERTIFICATE_ID_REQUEST:
      return produce(state, draftState => {
        draftState.certificateForm.isLoadingOrder = true
      })
    case GET_ORDER_BY_SOLD_CERTIFICATE_ID_SUCCESS:
      return produce(state, draftState => {
        draftState.certificateForm.isLoadingOrder = false
        draftState.certificateModalData.isOpen = true

        if (action.payload.data.id) {
          draftState.certificateModalData.order = action.payload.data
        } else {
          draftState.certificateModalData.order.id = null
        }
      })
    default:
      return state
  }
}
