import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from 'antd'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'

import {
  getPlacesList,
  getEventsList,
  getCategoriesList,
  getEventTypesList,
} from '../../../redux/actions/CommonActions'

import {
  setDateRange,
  setFieldForReport,
  resetFilters,
} from '../../../redux/actions/ReportActions'

import getUrlParamsFromObj from '../../../utils/getUrlParamsFromObj'
import downloadFile from '../../../utils/downloadFile'

const { RangePicker } = DatePicker
const { Option } = Select

class Report extends PureComponent {
  state = {
    eventsList: [],
    isDownloadingReport: false,
    isDownloadingReport2: false,
    isDownloadingSubscriptionsReport: false,
    isDownloadingCertificatesReport: false,
  }

  componentDidMount() {
    this.props.getEventTypesList()
    this.props.getPlacesList()
    this.props.getCategoriesList()
    this.props.getEventsList()
  }

  componentWillReceiveProps() {
    this.setState({
      eventsList: this.props.common.events,
    })
  }

  searchEvents = value => {
    value = value.toLowerCase()
    const searchList = this.props.common.events
    let eventsList = []
    searchList.forEach(item => {
      if (item.name.toLowerCase().includes(value)) {
        eventsList.push(item)
      }
    })
    this.setState({ eventsList })
  }

  render() {
    const { filterForm, filterForm2, subscriptionsFilterForm, certificatesFilterForm } = this.props.report

    const url = process.env.REACT_APP_API_HOST
    const token = this.props.user.attributes.token

    const urlParams = getUrlParamsFromObj(filterForm)
    const urlParams2 = getUrlParamsFromObj(filterForm2)
    const subscriptionsUrlParams = getUrlParamsFromObj(subscriptionsFilterForm)
    const certificatesUrlParams = getUrlParamsFromObj(certificatesFilterForm)

    return (
      <div>
        <Helmet>
          <title>Отчеты</title>
        </Helmet>

        <h1>Билеты</h1>

        <h3>Отчет №1</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <RangePicker
              placeholder={['Дата продажи c', 'Дата продажи по']}
              format={'DD.MM.YYYY'}
              style={{ width: '80%' }}
              onChange={(value, dateString) =>
                this.props.setDateRange(
                  'sale_date',
                  [
                    moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  ],
                  'filterForm'
                )
              }
              value={[
                filterForm.date_from
                  ? moment(filterForm.date_from, 'YYYY-MM-DD')
                  : null,
                filterForm.date_to
                  ? moment(filterForm.date_to, 'YYYY-MM-DD')
                  : null,
              ]}
            />
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Места"
              filterOption={false}
              value={filterForm.place}
              onChange={value => {
                this.props.setFieldForReport('place', value, 'filterForm')
              }}
            >
              {this.props.common.places.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Статус заказа"
              filterOption={false}
              value={filterForm.order_status}
              onChange={value => {
                this.props.setFieldForReport('order_status', value, 'filterForm')
              }}
            >
              <Option value={'ACTIVE'}>Активен</Option>
              <Option value={'PAID'}>Оплачен</Option>
              <Option value={'CANCELED'}>Отменен</Option>
              <Option value={'RETURNED'}>Возвращен</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Тип события"
              filterOption={false}
              value={filterForm.event_type}
              onChange={value => {
                this.props.setFieldForReport('event_type', value, 'filterForm')
              }}
            >
              <Option value={'0'}>
                Без события
              </Option>
              {this.props.common.event_types.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Событие"
              showSearch
              onSearch={this.searchEvents}
              filterOption={false}
              value={filterForm.event}
              onChange={value => {
                this.props.setFieldForReport('event', value, 'filterForm')
              }}
            >
              <Option value={'0'}>
                Без события
              </Option>
              {this.state.eventsList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Категория"
              filterOption={false}
              value={filterForm.category}
              onChange={value => {
                this.props.setFieldForReport('category', value, 'filterForm')
              }}
            >
              {this.props.common.categories.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <RangePicker
              placeholder={['Дата события c', 'Дата события по']}
              format={'DD.MM.YYYY'}
              style={{ width: '80%' }}
              onChange={(value, dateString) =>
                this.props.setDateRange(
                  'event_date',
                  [
                    moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  ],
                  filterForm
                )
              }
              value={[
                filterForm.event_date_from
                  ? moment(filterForm.event_date_from, 'YYYY-MM-DD')
                  : null,
                filterForm.event_date_to
                  ? moment(filterForm.event_date_to, 'YYYY-MM-DD')
                  : null,
              ]}
            />
          </Col>

          <Col span={7}>
            Цена от{' '}
            <InputNumber
              min={0}
              value={filterForm.price_from}
              style={{ width: '75px', marginRight: '5px' }}
              onChange={value => {
                this.props.setFieldForReport('price_from', value, 'filterForm')
              }}
            /> {''}
            до{' '}
            <InputNumber
              min={0}
              value={filterForm.price_to}
              style={{ width: '75px' }}
              onChange={value => {
                this.props.setFieldForReport('price_to', value, 'filterForm')
              }}
            />
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              placeholder="Тип отчета"
              filterOption={false}
              value={filterForm.report_type}
              onChange={value => {
                this.props.setFieldForReport('report_type', value, 'filterForm')

                if (value === 'full') {
                  this.props.setFieldForReport('is_online', [], 'filterForm')
                } else {
                  this.props.setFieldForReport('is_online', [0], 'filterForm')
                }
              }}
            >
              <Option value={'full'}>Полный</Option>
              <Option value={'cashier'}>Для кассира</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}></Col>
          <Col span={7}></Col>
          <Col span={7}>
            <Button
              type="primary"
              loading={this.state.isDownloadingReport}
              onClick={e => {
                this.setState({ isDownloadingReport: true })
                downloadFile(
                  url + '/ticket/get-report' + urlParams,
                  'Tickets_report.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingReport: false })
                  }
                )
              }}
            >
              Сформировать
            </Button>{' '}
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('filterForm')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <br />

        <h3>
          Отчет №2
        </h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <RangePicker
              placeholder={['Дата продажи c', 'Дата продажи по']}
              format={'DD.MM.YYYY'}
              style={{ width: '80%' }}
              onChange={(value, dateString) =>
                this.props.setDateRange(
                  'sale_date',
                  [
                    moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  ],
                  'filterForm2'
                )
              }
              value={[
                filterForm2.date_from
                  ? moment(filterForm2.date_from, 'YYYY-MM-DD')
                  : null,
                filterForm2.date_to
                  ? moment(filterForm2.date_to, 'YYYY-MM-DD')
                  : null,
              ]}
            />
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Места"
              filterOption={false}
              value={filterForm2.place}
              onChange={value => {
                this.props.setFieldForReport('place', value, 'filterForm2')
              }}
            >
              {this.props.common.places.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Статус заказа"
              filterOption={false}
              value={filterForm2.order_status}
              onChange={value => {
                this.props.setFieldForReport('order_status', value, 'filterForm2')
              }}
            >
              <Option value={'ACTIVE'}>Активен</Option>
              <Option value={'PAID'}>Оплачен</Option>
              <Option value={'CANCELED'}>Отменен</Option>
              <Option value={'RETURNED'}>Возвращен</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}></Col>

          <Col span={7}></Col>

          <Col span={7}>
            <Button
              type="primary"
              loading={this.state.isDownloadingReport2}
              onClick={e => {
                this.setState({ isDownloadingReport2: true })
                downloadFile(
                  url + '/ticket/get-report-2' + urlParams2,
                  'Tickets_report_2.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingReport2: false })
                  }
                )
              }}
            >
              Сформировать
            </Button>{' '}
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('filterForm2')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <h1>Абонементы</h1>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <RangePicker
              placeholder={['Дата продажи c', 'Дата продажи по']}
              format={'DD.MM.YYYY'}
              style={{ width: '80%' }}
              onChange={(value, dateString) =>
                this.props.setDateRange(
                  'sale_date',
                  [
                    moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  ],
                  'subscriptionsFilterForm'
                )
              }
              value={[
                subscriptionsFilterForm.date_from
                  ? moment(subscriptionsFilterForm.date_from, 'YYYY-MM-DD')
                  : null,
                subscriptionsFilterForm.date_to
                  ? moment(subscriptionsFilterForm.date_to, 'YYYY-MM-DD')
                  : null,
              ]}
            />
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              placeholder="Тип отчета"
              filterOption={false}
              value={subscriptionsFilterForm.report_type}
              onChange={value => {
                this.props.setFieldForReport('report_type', value, 'subscriptionsFilterForm')
              }}
            >
              <Option value={'sales'}>Продажи</Option>
              <Option value={'profit'}>Распределение прибыли</Option>
            </Select>
            {/*
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Места"
              filterOption={false}
              value={subscriptionsFilterForm.place}
              onChange={value => {
                this.props.setFieldForReport('place', value, 'subscriptionsFilterForm')
              }}
            >
              {this.props.common.places.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
              */}
          </Col>

          <Col span={7}>
            <Select
              style={{ width: '80%' }}
              mode="multiple"
              placeholder="Статус заказа"
              filterOption={false}
              value={subscriptionsFilterForm.order_status}
              onChange={value => {
                this.props.setFieldForReport('order_status', value, 'subscriptionsFilterForm')
              }}
            >
              <Option value={'ACTIVE'}>Активен</Option>
              <Option value={'PAID'}>Оплачен</Option>
              <Option value={'CANCELED'}>Отменен</Option>
              <Option value={'RETURNED'}>Возвращен</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            {/*
            <Select
              style={{ width: '80%' }}
              placeholder="Тип отчета"
              filterOption={false}
              value={subscriptionsFilterForm.report_type}
              onChange={value => {
                this.props.setFieldForReport('report_type', value, 'subscriptionsFilterForm')
              }}
            >
              <Option value={'sales'}>Продажи</Option>
              <Option value={'profit'}>Распределение прибыли</Option>
            </Select>
            */}
          </Col>

          <Col span={7}></Col>

          <Col span={7}>
            <Button
              type="primary"
              loading={this.state.isDownloadingSubscriptionsReport}
              onClick={e => {
                this.setState({ isDownloadingSubscriptionsReport: true })
                downloadFile(
                  url + '/subscription-report/get-report' + subscriptionsUrlParams,
                  'Subscriptions_report.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingSubscriptionsReport: false })
                  }
                )
              }}
            >
              Сформировать
            </Button>{' '}
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('subscriptionsFilterForm')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <h1>Сертификаты</h1>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <RangePicker
              placeholder={['Дата продажи c', 'Дата продажи по']}
              format={'DD.MM.YYYY'}
              style={{ width: '80%' }}
              onChange={(value, dateString) =>
                this.props.setDateRange(
                  'sale_date',
                  [
                    moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  ],
                  'certificatesFilterForm'
                )
              }
              value={[
                certificatesFilterForm.date_from
                  ? moment(certificatesFilterForm.date_from, 'YYYY-MM-DD')
                  : null,
                certificatesFilterForm.date_to
                  ? moment(certificatesFilterForm.date_to, 'YYYY-MM-DD')
                  : null,
              ]}
            />
          </Col>

          <Col span={7}></Col>

          <Col span={7}>
            <Button
              type="primary"
              loading={this.state.isDownloadingCertificatesReport}
              onClick={e => {
                this.setState({ isDownloadingCertificatesReport: true })
                downloadFile(
                  url + '/certificate-report/get-report' + certificatesUrlParams,
                  'Certificates_report.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingCertificatesReport: false })
                  }
                )
              }}
            >
              Сформировать
            </Button>{' '}
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('certificatesFilterForm')
              }}
            >
              сбросить
            </Button>
          </Col>

        </Row>

      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    common: store.common,
    report: store.report,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEventTypesList: () => dispatch(getEventTypesList()),
    getPlacesList: () => dispatch(getPlacesList()),
    getCategoriesList: () => dispatch(getCategoriesList()),
    getEventsList: () => dispatch(getEventsList()),
    setDateRange: (type, dateString, form) => dispatch(setDateRange(type, dateString, form)),
    setFieldForReport: (field, value, form) =>
      dispatch(setFieldForReport(field, value, form)),
    resetFilters: (form) => dispatch(resetFilters(form)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report)
