import produce from 'immer'
import moment from 'moment'

import {
  SET_FIELD_FOR_CASHIER,
  TICKETS_LOAD_REQUEST,
  TICKETS_LOAD_SUCCESS,
  SET_CATEGORIES_FOR_TICKET,
  UPDATE_CATEGORY_NUMBER,
  SEND_MANUAL_REQUEST,
  SEND_MANUAL_SUCCESS,
  UPLOAD_FILE,
  SET_TRANSACTION_NUMBER,
  SEND_OFFLINE_RRN_REQUEST,
  SEND_OFFLINE_RRN_SUCCESS,
  RESET_MESSAGE,
} from '../actions/CashierActions'

const initialState = {
  manualForm: {
    isSending: false,
    message: '',

    date: moment().format('YYYY-MM-DD'),
    place_id: undefined,
    ticket_id: undefined,
    categories: [],

    isLoadingTickets: false,
    tickets: [],
  },
  unloading: {
    isUploadFile: false,
    isSendingReport: false,
    message: '',

    data: null,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return produce(state, draftState => {
        draftState[action.payload.section].message = ''
      })
    case SEND_OFFLINE_RRN_SUCCESS:
      return produce(state, draftState => {
        draftState.unloading.isSendingReport = false
        draftState.unloading.data = null
        draftState.unloading.message = 'Данные сохранены'
      })
    case SEND_OFFLINE_RRN_REQUEST:
      return produce(state, draftState => {
        draftState.unloading.isSendingReport = true
        draftState.unloading.message = ''
      })
    case SET_TRANSACTION_NUMBER:
      return produce(state, draftState => {
        if (action.payload.terminalId && action.payload.orderIndex === null) {
          draftState.unloading.data[action.payload.terminalId].transactions.forEach((item, index) => {
            if (draftState.unloading.data[action.payload.terminalId].orders[index]) {
              draftState.unloading.data[action.payload.terminalId].orders[index].date = item.date
              draftState.unloading.data[action.payload.terminalId].orders[index].rrn = item.rrn
            }
          })
        } else {
          draftState.unloading.data[action.payload.terminalId].orders[action.payload.orderIndex][[action.payload.type]] = action.payload.value
        }
      })
    case UPLOAD_FILE:
      return produce(state, draftState => {
        draftState.unloading.isUploadFile = action.payload.isLoading
        draftState.unloading.data = action.payload.data
      })
    case SEND_MANUAL_REQUEST:
      return produce(state, draftState => {
        draftState.manualForm.isSending = true
        draftState.manualForm.message = ''
      })
    case SEND_MANUAL_SUCCESS:
      return produce(state, draftState => {
        draftState.manualForm.isSending = false
        if (action.payload.data) {
          draftState.manualForm.message = 'Данные сохранены'
        }
      })
    case UPDATE_CATEGORY_NUMBER:
      return produce(state, draftState => {
        for (let i = 0; i < draftState.manualForm.categories.length; i++) {
          if (
            draftState.manualForm.categories[i].category_id === action.payload.id
          ) {
            draftState.manualForm.categories[i].number = action.payload.number
            draftState.manualForm.categories[i].sum =
              draftState.manualForm.categories[i].price *
              draftState.manualForm.categories[i].number
          }
        }
      })
    case SET_CATEGORIES_FOR_TICKET:
      return produce(state, draftState => {
        let ticket = {}
        draftState.manualForm.tickets.every(item => {
          if (item.id === action.payload.ticket_id) {
            ticket = item
            return false
          }
          return true
        })
        draftState.manualForm.categories = ticket.categories.map(item => ({
          ...item,
          number: 0,
          sum: 0,
        }))
      })
    case TICKETS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.manualForm.isLoadingTickets = false
        draftState.manualForm.tickets = action.payload.data
      })
    case TICKETS_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.manualForm.isLoadingTickets = true
      })
    case SET_FIELD_FOR_CASHIER:
      return produce(state, draftState => {
        draftState.manualForm[action.payload.field] = action.payload.value
        draftState.manualForm.message = ''
      })
    default:
      return state
  }
}