import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  Icon,
  Table,
  Radio,
  Spin,
  Drawer,
  Popconfirm,
  Divider,
} from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { TweenOneGroup } from 'rc-tween-one'
import { EditOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  setAvailableForSale,
  resetFilters,
  applyFilters,
  getCertificatesList,
  copyCertificate,
  deleteCertificate,
  openCertificateForm,
  closeCertificateForm,
  openEditCertificateForm,
} from '../../../redux/actions/CertificatesActions'

import CertificateForm from './Components/CertificateFormComponent'

const TableContext = React.createContext(false)

const enterAnim = [
  {
    opacity: 0,
    x: 30,
    backgroundColor: '#fffeee',
    duration: 0,
  },
  {
    height: 0,
    duration: 200,
    type: 'from',
    delay: 250,
    ease: 'easeOutQuad',
  },
  {
    opacity: 1,
    x: 0,
    duration: 250,
    ease: 'easeOutQuad',
  },
  { delay: 1000, backgroundColor: '#fff' },
]

const leaveAnim = [
  { duration: 250, opacity: 0 },
  { height: 0, duration: 200, ease: 'easeOutQuad' },
]

class Certificates extends PureComponent {
  animTag = $props => {
    return (
      <TableContext.Consumer>
        {() => {
          return (
            <TweenOneGroup
              component="tbody"
              enter={this.props.certificates.animation ? enterAnim : null}
              leave={this.props.certificates.animation ? leaveAnim : null}
              appear={false}
              exclusive
              {...$props}
            />
          )
        }}
      </TableContext.Consumer>
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc',
      }
    }
    this.props.getCertificatesList(1, sort, {
      ...this.props.certificates.filter,
    })
    this.props.applyFilters()
  }

  handleScroll = e => {
    if (
      this.props.certificates.isLoadingCertificates ||
      this.props.certificates.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.certificates.pageNumber + 1
      this.props.getCertificatesList(
        pageNumber,
        this.props.certificates.sort,
        this.props.certificates.filter
      )
    }
  }

  componentDidMount() {
    this.props.getCertificatesList(1, this.props.certificates.sort, this.props.certificates.filter)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getCertificateStatus = is_available_for_sale => {
    switch (is_available_for_sale) {
      case 1:
        return (
          <Icon
            type="play-circle"
            className="Activity__agreed-event"
            title="В продаже"
          />
        )
      default:
        return (
          <Icon
            type="pause-circle"
            className="Activity__consistent-event"
            title="Не продается"
          />
        )
    }
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const data = this.props.certificates.certificates.map(item => ({
      key: item.id,
      id: item.id,
      status: this.getCertificateStatus(item.is_available_for_sale),
      price: item.price,
      certificates_sold: item.certificates_sold,
      booked_certificates: item.booked_certificates,
    }))

    const columns = [
      {
        title: '',
        dataIndex: 'status',
        className: 'Activity__table-cell',
        width: 60,
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditCertificateForm(record.id)
          },
        }),
      },
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        className: 'Activity__table-cell',
        width: 100,
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditCertificateForm(record.id)
          },
        }),
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditCertificateForm(record.id)
          },
        }),
      },
      {
        title: 'Продано',
        dataIndex: 'certificates_sold',
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditCertificateForm(record.id)
          },
        }),
      },
      {
        title: '',
        key: 'action',
        width: 130,
        render: (text, record) => (
          <span className="Activity__control-buttons">
            <a
              href="/"
              title="Копировать"
              onClick={e => {
                e.preventDefault()
                this.props.copyCertificate(record.id)
              }}
            >
              <CopyOutlined />
            </a>
            <Divider type="vertical" />
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.openEditCertificateForm(record.id)
              }}
            >
              <EditOutlined />
            </a>
            {record.booked_certificates === 0 && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить запись?"
                  onConfirm={e => this.props.deleteCertificate(record.id)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </>
            )}
          </span>
        ),
      },
    ]

    const { filterForm } = this.props.certificates
    let additionalTableParams = {}
    if (this.props.certificates.animation) {
      additionalTableParams.components = { body: { wrapper: this.animTag } }
    }

    let tableStyle = null
    if (
      this.props.certificates.isLoadingCertificates &&
      this.props.certificates.pageNumber === 1
    ) {
      tableStyle = { display: 'none' }
    }

    return (
      <div>
        <Helmet>
          <title>Сертификаты</title>
        </Helmet>

        <h1>
          Сертификаты
          <Button
            type="primary"
            className="Activity__add-button"
            onClick={this.props.openCertificateForm}
          >
            Создать
          </Button>
        </h1>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <Radio.Group
              onChange={e => {
                const value = e.target.value
                this.props.setAvailableForSale(value)
                this.props.applyFilters()
                this.props.getCertificatesList(1, this.props.certificates.sort, {
                  ...this.props.certificates.filter,
                  available_for_sale: value,
                })
              }}
              value={filterForm.available_for_sale}
            >
              <Radio.Button value="1">В продаже</Radio.Button>
              <Radio.Button value="0">Не продаются</Radio.Button>
              <Radio.Button value="all">Все</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <TableContext.Provider>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={this.handleTableChange}
            style={tableStyle}
            {...additionalTableParams}
          />
        </TableContext.Provider>

        {this.props.certificates.isLoadingCertificates && (
          <div className="Activity__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={
            !this.props.certificates.certificateForm.id ? 'Создание' : 'Редактирование'
          }
          width={'700'}
          onClose={this.props.closeCertificateForm}
          visible={this.props.certificates.certificateForm.isOpen}
        >
          <CertificateForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    certificates: store.certificates,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCertificatesList: (pageNumber = 1, sort = null, filterForm = null) =>
      dispatch(getCertificatesList(pageNumber, sort, filterForm)),
    setAvailableForSale: value => dispatch(setAvailableForSale(value)),
    resetFilters: () => dispatch(resetFilters()),
    applyFilters: () => dispatch(applyFilters()),
    copyCertificate: id => dispatch(copyCertificate(id)),
    deleteCertificate: id => dispatch(deleteCertificate(id)),
    openCertificateForm: () => dispatch(openCertificateForm()),
    closeCertificateForm: () => dispatch(closeCertificateForm()),
    openEditCertificateForm: id => dispatch(openEditCertificateForm(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Certificates)