import produce from 'immer'

import {
  SET_AVAILABLE_FOR_SALE,
  SET_DATE_RANGE,
  RESET_FILTERS,
  APPLY_FILTERS,
  SUBSCRIPTIONS_LOAD_REQUEST,
  SUBSCRIPTIONS_LOAD_SUCCESS,
  COPY_SUBSCRIPTION_REQUEST,
  COPY_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_REQUEST,
  DELETE_SUBSCRIPTION_SUCCESS,
  OPEN_SUBSCRIPTION_FORM,
  CLOSE_SUBSCRIPTION_FORM,
  OPEN_EDIT_SUBSCRIPTION_FORM,
  SET_FIELD_FOR_SUBSCRIPTION,
  UPDATE_CATEGORIES,
  SET_PRICE_FOR_CATEGORY,
  UPDATE_PLACES,
  SET_NUM_VISITS_FOR_PLACE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from '../actions/SubscriptionsActions'

const initialState = {
  isLoadingSubscriptions: false,
  subscriptions: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc',
  },
  filter: {
    dateFrom: null,
    dateTo: null,
    available_for_sale: '1',
  },

  filterForm: {
    dateFrom: null,
    dateTo: null,
    available_for_sale: '1',
  },

  subscriptionForm: {
    id: null,
    title: null,
    title_en: null,
    receipt_title: null,
    subtitle: null,
    subtitle_en: null,
    sales_limit: 0,
    order_limit: null,
    is_available_for_sale: 0,
    date_valid_from: null,
    date_valid_to: null,
    color: null,
    comment: null,
    comment_en: null,
    is_pushka: 0,
    pro_culture_event_id: null,
    pro_culture_place_id: null,
    age_limit: null,
    is_link_only_access: 0,
    link_access_code: null,

    place_ids: [],
    places: [],

    category_ids: [],
    categories: [],

    booked_subscriptions: 0,
    subscriptions_sold: 0,

    isOpen: false,

    isSending: false,
  },

  isDeletingSubscription: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTIONS_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingSubscriptions = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.subscriptions = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case SUBSCRIPTIONS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingSubscriptions = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.subscriptions = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.subscriptions = draftState.subscriptions.concat(action.payload.data)
        }
      })
    case APPLY_FILTERS:
      return produce(state, draftState => {
        draftState.filter = draftState.filterForm
      })
    case RESET_FILTERS:
      return produce(state, draftState => {
        draftState.filter = initialState.filter
        draftState.filterForm = initialState.filterForm
      })
    case SET_AVAILABLE_FOR_SALE:
      return produce(state, draftState => {
        draftState.filterForm.available_for_sale = action.payload.value
      })
    case SET_DATE_RANGE:
      return produce(state, draftState => {
        draftState.filterForm.dateFrom = action.payload.dateFrom
        draftState.filterForm.dateTo = action.payload.dateTo
      })
    case COPY_SUBSCRIPTION_REQUEST:
      return produce(state, draftState => {
        draftState.animation = true
      })
    case COPY_SUBSCRIPTION_SUCCESS:
      return produce(state, draftState => {
        const { data } = action.payload
        draftState.subscriptions.unshift(data)
      })
    case DELETE_SUBSCRIPTION_SUCCESS:
      return produce(state, draftState => {
        draftState.isDeletingSubscription = false
      })
    case DELETE_SUBSCRIPTION_REQUEST:
      return produce(state, draftState => {
        draftState.isDeletingSubscription = true
        draftState.animation = true

        draftState.subscriptions.splice(
          draftState.subscriptions.findIndex(item => item.id === action.payload.id),
          1
        )
      })
    case CLOSE_SUBSCRIPTION_FORM:
      return produce(state, draftState => {
        draftState.subscriptionForm.isOpen = false
      })
    case OPEN_SUBSCRIPTION_FORM:
      return produce(state, draftState => {
        draftState.subscriptionForm = {
          ...initialState.subscriptionForm,
          isOpen: true,
        }
      })
    case OPEN_EDIT_SUBSCRIPTION_FORM:
      return produce(state, draftState => {
        let subscription = draftState.subscriptions.find(
          item => item.id === action.payload.id
        )

        draftState.subscriptionForm.id = subscription.id
        draftState.subscriptionForm.title = subscription.title
        draftState.subscriptionForm.title_en = subscription.title_en
        draftState.subscriptionForm.receipt_title = subscription.receipt_title
        draftState.subscriptionForm.subtitle = subscription.subtitle
        draftState.subscriptionForm.subtitle_en = subscription.subtitle_en
        draftState.subscriptionForm.sales_limit = subscription.sales_limit
        draftState.subscriptionForm.order_limit = subscription.order_limit
        draftState.subscriptionForm.is_available_for_sale =
          subscription.is_available_for_sale
        draftState.subscriptionForm.date_valid_from = subscription.date_valid_from
        draftState.subscriptionForm.date_valid_to = subscription.date_valid_to
        draftState.subscriptionForm.color = subscription.color
        draftState.subscriptionForm.comment = subscription.comment
        draftState.subscriptionForm.comment_en = subscription.comment_en
        draftState.subscriptionForm.is_pushka = subscription.is_pushka
        draftState.subscriptionForm.pro_culture_event_id = subscription.pro_culture_event_id
        draftState.subscriptionForm.pro_culture_place_id = subscription.pro_culture_place_id
        draftState.subscriptionForm.age_limit = subscription.age_limit
        draftState.subscriptionForm.is_link_only_access = subscription.is_link_only_access
        draftState.subscriptionForm.link_access_code = subscription.link_access_code

        draftState.subscriptionForm.is_date = subscription.date_valid_from ? 1 : 0

        draftState.subscriptionForm.category_ids = subscription.categories
          ? subscription.categories.map(item => item.category_id + '')
          : []
        draftState.subscriptionForm.categories = subscription.categories
          ? subscription.categories.map(item => ({
            category_id: item.category_id + '',
            price: item.price,
          }))
          : []

        draftState.subscriptionForm.place_ids = subscription.places
          ? subscription.places.map(item => item.place_id + '')
          : []
        draftState.subscriptionForm.places = subscription.places
          ? subscription.places.map(item => ({
            place_id: item.place_id + '',
            num_visits: item.num_visits,
          }))
          : []

        draftState.subscriptionForm.booked_subscriptions = subscription.booked_subscriptions
        draftState.subscriptionForm.subscriptions_sold = subscription.subscriptions_sold

        draftState.subscriptionForm.isOpen = true
      })
    case SET_FIELD_FOR_SUBSCRIPTION:
      return produce(state, draftState => {
        draftState.subscriptionForm[action.payload.field] = action.payload.value
      })
    case UPDATE_CATEGORIES:
      return produce(state, draftState => {
        let newCategoriesList = []
        let currentCategoriesIds = []

        draftState.subscriptionForm.categories.forEach(item => {
          if (action.payload.value.includes(item.category_id)) {
            newCategoriesList.push(item)
          }
          currentCategoriesIds.push(item.category_id)
        })

        action.payload.value.forEach(id => {
          if (!currentCategoriesIds.includes(id)) {
            newCategoriesList.push({
              category_id: id,
              price: 0,
            })
          }
        })

        draftState.subscriptionForm.categories = newCategoriesList
      })
    case SET_PRICE_FOR_CATEGORY:
      return produce(state, draftState => {
        draftState.subscriptionForm.categories.forEach((item, index) => {
          if (parseInt(item.category_id) === parseInt(action.payload.id)) {
            draftState.subscriptionForm.categories[index].price = action.payload.value
          }
        })
      })
    case UPDATE_PLACES:
      return produce(state, draftState => {
        let newPlacesList = []
        let currentPlacesIds = []

        draftState.subscriptionForm.places.forEach(item => {
          if (action.payload.value.includes(item.place_id)) {
            newPlacesList.push(item)
          }
          currentPlacesIds.push(item.place_id)
        })

        action.payload.value.forEach(id => {
          if (!currentPlacesIds.includes(id)) {
            newPlacesList.push({
              place_id: id,
              num_visits: 1,
            })
          }
        })

        draftState.subscriptionForm.places = newPlacesList
      })
    case SET_NUM_VISITS_FOR_PLACE:
      return produce(state, draftState => {
        draftState.subscriptionForm.places.forEach((item, index) => {
          if (parseInt(item.place_id) === parseInt(action.payload.id)) {
            draftState.subscriptionForm.places[index].num_visits = action.payload.value
          }
        })
      })
    case CREATE_SUBSCRIPTION_SUCCESS:
      return produce(state, draftState => {
        draftState.subscriptionForm.isSending = false
        draftState.subscriptionForm.isOpen = false
        const { data } = action.payload
        draftState.subscriptions.unshift(data)
      })
    case CREATE_SUBSCRIPTION_REQUEST:
      return produce(state, draftState => {
        draftState.subscriptionForm.isSending = true
        draftState.animation = true
      })
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return produce(state, draftState => {
        draftState.subscriptionForm.isSending = false
        draftState.subscriptionForm.isOpen = false

        let subscriptionIndex = draftState.subscriptions.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.subscriptions[subscriptionIndex] = action.payload.data
      })
    case UPDATE_SUBSCRIPTION_REQUEST:
      return produce(state, draftState => {
        draftState.subscriptionForm.isSending = true
      })
    default:
      return state
  }
}