export const SET_DATE_RANGE = 'REPORT/SET_DATE_RANGE'

export function setDateRange(type, dateString, form = 'filterForm') {
  return dispatch => {
    dispatch({
      type: SET_DATE_RANGE,
      payload: {
        type,
        date_from: dateString[0],
        date_to: dateString[1],
        form,
      },
    })
  }
}

export const SET_FIELD_FOR_REPORT = 'REPORT/SET_FIELD_FOR_REPORT'

export function setFieldForReport(field, value, form = 'filterForm') {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_REPORT,
      payload: {
        field,
        value,
        form,
      },
    })
  }
}

export const RESET_FILTERS = 'REPORT/RESET_FILTERS'

export function resetFilters(form = 'filterForm') {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
      payload: {
        form,
      },
    })
  }
}