export const SET_FIELD_FOR_ORDER = 'ORDER/SET_FIELD_FOR_ORDER'

export function setFieldForOrder(field, value, form = 'filterForm') {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_ORDER,
      payload: {
        field,
        value,
        form,
      },
    })
  }
}

export const CLOSE_MODAL = 'ORDER/CLOSE_MODAL'

export function closeModal(modal = 'modalData') {
  return dispatch => {
    dispatch({
      type: CLOSE_MODAL,
      payload: {
        modal,
      },
    })
  }
}

// tickets START
export const GET_ORDER_REQUEST = 'ORDER/GET_ORDER_REQUEST'
export const GET_ORDER_SUCCESS = 'ORDER/GET_ORDER_SUCCESS'

export function getOrder(id) {
  return dispatch => {
    dispatch({
      types: [GET_ORDER_REQUEST, GET_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/order/get/${id}`,
          params: {},
        },
      },
    })
  }
}

export const REFUND_ORDER_REQUEST = 'ORDER/REFUND_ORDER_REQUEST'
export const REFUND_ORDER_SUCCESS = 'ORDER/REFUND_ORDER_SUCCESS'

export function refundOrder(id, refundRrn = '') {
  return dispatch => {
    dispatch({
      types: [REFUND_ORDER_REQUEST, REFUND_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/order/refund/${id}`,
          params: {
            refund_rrn: refundRrn,
            send_receipt: true,
          },
        },
      },
    })
  }
}

export const GET_ORDER_BY_SOLD_TICKET_ID_REQUEST = 'ORDER/GET_ORDER_BY_SOLD_TICKET_ID_REQUEST'
export const GET_ORDER_BY_SOLD_TICKET_ID_SUCCESS = 'ORDER/GET_ORDER_BY_SOLD_TICKET_ID_SUCCESS'

export function getOrderBySoldTicketId(id) {
  return dispatch => {
    dispatch({
      types: [GET_ORDER_BY_SOLD_TICKET_ID_REQUEST, GET_ORDER_BY_SOLD_TICKET_ID_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/order/get-by-sold-ticket-id/${id}`,
          params: {},
        },
      },
    })
  }
}
// tickets END

// subscriptions START
export const GET_SUBSCRIPTION_ORDER_REQUEST = 'ORDER/GET_SUBSCRIPTION_ORDER_REQUEST'
export const GET_SUBSCRIPTION_ORDER_SUCCESS = 'ORDER/GET_SUBSCRIPTION_ORDER_SUCCESS'

export function getSubscriptionOrder(id) {
  return dispatch => {
    dispatch({
      types: [GET_SUBSCRIPTION_ORDER_REQUEST, GET_SUBSCRIPTION_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/subscription-order/get/${id}`,
          params: {},
        },
      },
    })
  }
}

export const REFUND_SUBSCRIPTION_ORDER_REQUEST = 'ORDER/REFUND_SUBSCRIPTION_ORDER_REQUEST'
export const REFUND_SUBSCRIPTION_ORDER_SUCCESS = 'ORDER/REFUND_SUBSCRIPTION_ORDER_SUCCESS'

export function refundSubscriptionOrder(id, refundRrn = '') {
  return dispatch => {
    dispatch({
      types: [REFUND_SUBSCRIPTION_ORDER_REQUEST, REFUND_SUBSCRIPTION_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/subscription-order/refund/${id}`,
          params: {
            refund_rrn: refundRrn,
            send_receipt: true,
          },
        },
      },
    })
  }
}

export const GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_REQUEST = 'ORDER/GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_REQUEST'
export const GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_SUCCESS = 'ORDER/GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_SUCCESS'

export function getOrderBySoldSubscriptionId(id) {
  return dispatch => {
    dispatch({
      types: [GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_REQUEST, GET_ORDER_BY_SOLD_SUBSCRIPTION_ID_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/subscription-order/get-by-sold-subscription-id/${id}`,
          params: {},
        },
      },
    })
  }
}
// subscriptions END

// certificates START
export const GET_CERTIFICATE_ORDER_REQUEST = 'ORDER/GET_CERTIFICATE_ORDER_REQUEST'
export const GET_CERTIFICATE_ORDER_SUCCESS = 'ORDER/GET_CERTIFICATE_ORDER_SUCCESS'

export function getCertificateOrder(id) {
  return dispatch => {
    dispatch({
      types: [GET_CERTIFICATE_ORDER_REQUEST, GET_CERTIFICATE_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/certificate-order/get/${id}`,
          params: {},
        },
      },
    })
  }
}

export const REFUND_CERTIFICATE_ORDER_REQUEST = 'ORDER/REFUND_CERTIFICATE_ORDER_REQUEST'
export const REFUND_CERTIFICATE_ORDER_SUCCESS = 'ORDER/REFUND_CERTIFICATE_ORDER_SUCCESS'

export function refundCertificateOrder(id, refundRrn = '') {
  return dispatch => {
    dispatch({
      types: [REFUND_CERTIFICATE_ORDER_REQUEST, REFUND_CERTIFICATE_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/certificate-order/refund/${id}`,
          params: {
            refund_rrn: refundRrn,
            send_receipt: true,
          },
        },
      },
    })
  }
}

export const GET_ORDER_BY_SOLD_CERTIFICATE_ID_REQUEST = 'ORDER/GET_ORDER_BY_SOLD_CERTIFICATE_ID_REQUEST'
export const GET_ORDER_BY_SOLD_CERTIFICATE_ID_SUCCESS = 'ORDER/GET_ORDER_BY_SOLD_CERTIFICATE_ID_SUCCESS'

export function getOrderBySoldCertificateId(id) {
  return dispatch => {
    dispatch({
      types: [GET_ORDER_BY_SOLD_CERTIFICATE_ID_REQUEST, GET_ORDER_BY_SOLD_CERTIFICATE_ID_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/certificate-order/get-by-sold-certificate-id/${id}`,
          params: {},
        },
      },
    })
  }
}
// certificates END