import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  Icon,
  Table,
  Radio,
  Spin,
  Drawer,
  Popconfirm,
  Divider,
  DatePicker,
} from 'antd'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import { TweenOneGroup } from 'rc-tween-one'
import { EditOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  setAvailableForSale,
  setDateRange,
  resetFilters,
  applyFilters,
  getTicketsList,
  copyTicket,
  deleteTicket,
  openTicketForm,
  closeTicketForm,
  openEditTicketForm,
  setPlace,
} from '../../../redux/actions/TicketsActions'
import { getPlacesList } from '../../../redux/actions/CommonActions'

import TicketForm from './Components/TicketFormComponent'

const { RangePicker } = DatePicker

const TableContext = React.createContext(false)

const enterAnim = [
  {
    opacity: 0,
    x: 30,
    backgroundColor: '#fffeee',
    duration: 0,
  },
  {
    height: 0,
    duration: 200,
    type: 'from',
    delay: 250,
    ease: 'easeOutQuad',
  },
  {
    opacity: 1,
    x: 0,
    duration: 250,
    ease: 'easeOutQuad',
  },
  { delay: 1000, backgroundColor: '#fff' },
]

const leaveAnim = [
  { duration: 250, opacity: 0 },
  { height: 0, duration: 200, ease: 'easeOutQuad' },
]

class Tickets extends PureComponent {
  animTag = $props => {
    return (
      <TableContext.Consumer>
        {() => {
          return (
            <TweenOneGroup
              component="tbody"
              enter={this.props.tickets.animation ? enterAnim : null}
              leave={this.props.tickets.animation ? leaveAnim : null}
              appear={false}
              exclusive
              {...$props}
            />
          )
        }}
      </TableContext.Consumer>
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc',
      }
    }
    this.props.getTicketsList(1, sort, {
      ...this.props.tickets.filter,
      place: filters.place ? filters.place : [],
    })
    this.props.setPlace(filters.place)
    this.props.applyFilters()
  }

  handleScroll = e => {
    if (
      this.props.tickets.isLoadingTickets ||
      this.props.tickets.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.tickets.pageNumber + 1
      this.props.getTicketsList(
        pageNumber,
        this.props.tickets.sort,
        this.props.tickets.filter
      )
    }
  }

  componentDidMount() {
    this.props.getPlacesList()
    this.props.getTicketsList(1, this.props.tickets.sort, this.props.tickets.filter)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getTicketStatus = is_available_for_sale => {
    switch (is_available_for_sale) {
      case 1:
        return (
          <Icon
            type="play-circle"
            className="Activity__agreed-event"
            title="В продаже"
          />
        )
      default:
        return (
          <Icon
            type="pause-circle"
            className="Activity__consistent-event"
            title="Не продается"
          />
        )
    }
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const data = this.props.tickets.tickets.map(item => ({
      key: item.id,
      id: item.id,
      status: this.getTicketStatus(item.is_available_for_sale),
      title: item.title,
      place: item.place.ticket_place_name,
      sales_limit: item.sales_limit === 0 ? '∞' : item.sales_limit,
      tickets_sold: item.tickets_sold,
      booked_tickets: item.booked_tickets,
      stock_balance:
        item.sales_limit === 0 ? '∞' : item.sales_limit - item.tickets_sold,
    }))

    const columns = [
      {
        title: '',
        dataIndex: 'status',
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: 'Название',
        dataIndex: 'title',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: 'Место',
        dataIndex: 'place',
        className: 'Activity__table-cell',
        filters: [
          ...this.props.common.places.map(item => ({
            text: item.name,
            value: item.id,
          })),
        ],
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: 'Лимит',
        dataIndex: 'sales_limit',
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: 'Продано',
        dataIndex: 'tickets_sold',
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: 'Остаток',
        dataIndex: 'stock_balance',
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditTicketForm(record.id)
          },
        }),
      },
      {
        title: '',
        key: 'action',
        width: 130,
        render: (text, record) => (
          <span className="Activity__control-buttons">
            <a
              href="/"
              title="Копировать"
              onClick={e => {
                e.preventDefault()
                this.props.copyTicket(record.id)
              }}
            >
              <CopyOutlined />
            </a>
            <Divider type="vertical" />
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.openEditTicketForm(record.id)
              }}
            >
              <EditOutlined />
            </a>
            {record.booked_tickets === 0 && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить запись?"
                  onConfirm={e => this.props.deleteTicket(record.id)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </>
            )}
          </span>
        ),
      },
    ]

    const { filterForm } = this.props.tickets
    let additionalTableParams = {}
    if (this.props.tickets.animation) {
      additionalTableParams.components = { body: { wrapper: this.animTag } }
    }

    let tableStyle = null
    if (
      this.props.tickets.isLoadingTickets &&
      this.props.tickets.pageNumber === 1
    ) {
      tableStyle = { display: 'none' }
    }

    return (
      <div>
        <Helmet>
          <title>Билеты</title>
        </Helmet>

        <h1>
          Билеты
          <Button
            type="primary"
            className="Activity__add-button"
            onClick={this.props.openTicketForm}
          >
            Создать
          </Button>
        </h1>

        <Row gutter={16} className="Activity__settings">
          <Col span={7}>
            <Radio.Group
              onChange={e => {
                const value = e.target.value
                this.props.setAvailableForSale(value)
                this.props.applyFilters()
                this.props.getTicketsList(1, this.props.tickets.sort, {
                  ...this.props.tickets.filter,
                  available_for_sale: value,
                })
              }}
              value={filterForm.available_for_sale}
            >
              <Radio.Button value="1">В продаже</Radio.Button>
              <Radio.Button value="0">Не продаются</Radio.Button>
              <Radio.Button value="all">Все</Radio.Button>
            </Radio.Group>
          </Col>

          <Col span={7}>
            <RangePicker
              format={'DD.MM.YYYY'}
              style={{ width: '80%' }}
              onChange={(value, dateString) =>
                this.props.setDateRange([
                  moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                ])
              }
              value={[
                filterForm.dateFrom
                  ? moment(filterForm.dateFrom, 'YYYY-MM-DD')
                  : null,
                filterForm.dateTo
                  ? moment(filterForm.dateTo, 'YYYY-MM-DD')
                  : null,
              ]}
            />
          </Col>

          <Col span={7}>
            <Button
              type="primary"
              onClick={e => {
                this.props.applyFilters()
                this.props.getTicketsList(
                  1,
                  this.props.tickets.sort,
                  filterForm
                )
              }}
            >
              Найти
            </Button>{' '}
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters()
                this.props.getTicketsList(1, this.props.tickets.sort, null)
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <TableContext.Provider>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={this.handleTableChange}
            style={tableStyle}
            {...additionalTableParams}
          />
        </TableContext.Provider>

        {this.props.tickets.isLoadingTickets && (
          <div className="Activity__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={
            !this.props.tickets.ticketForm.id ? 'Создание' : 'Редактирование'
          }
          width={'700'}
          onClose={this.props.closeTicketForm}
          visible={this.props.tickets.ticketForm.isOpen}
        >
          <TicketForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    tickets: store.tickets,
    common: store.common,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTicketsList: (pageNumber = 1, sort = null, filterForm = null) =>
      dispatch(getTicketsList(pageNumber, sort, filterForm)),
    setAvailableForSale: value => dispatch(setAvailableForSale(value)),
    setDateRange: dateString => dispatch(setDateRange(dateString)),
    resetFilters: () => dispatch(resetFilters()),
    applyFilters: () => dispatch(applyFilters()),
    getPlacesList: () => dispatch(getPlacesList()),
    copyTicket: id => dispatch(copyTicket(id)),
    deleteTicket: id => dispatch(deleteTicket(id)),
    openTicketForm: () => dispatch(openTicketForm()),
    closeTicketForm: () => dispatch(closeTicketForm()),
    openEditTicketForm: id => dispatch(openEditTicketForm(id)),
    setPlace: place => dispatch(setPlace(place)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tickets)
