export const SUBSCRIPTIONS_LOAD_REQUEST = 'SUBSCRIPTIONS/SUBSCRIPTIONS_LOAD_REQUEST'
export const SUBSCRIPTIONS_LOAD_SUCCESS = 'SUBSCRIPTIONS/SUBSCRIPTIONS_LOAD_SUCCESS'

export function getSubscriptionsList(pageNumber = 1, sort = null, filterForm = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    if (filterForm) {
      additionalParams.date_from = filterForm.dateFrom
      additionalParams.date_to = filterForm.dateTo
      additionalParams.available_for_sale = filterForm.available_for_sale
    }

    dispatch({
      types: [SUBSCRIPTIONS_LOAD_REQUEST, SUBSCRIPTIONS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/subscription/get-subscriptions-list',
          params: {
            page: pageNumber,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const SET_AVAILABLE_FOR_SALE = 'SUBSCRIPTIONS/SET_AVAILABLE_FOR_SALE'

export function setAvailableForSale(value) {
  return dispatch => {
    dispatch({
      type: SET_AVAILABLE_FOR_SALE,
      payload: {
        value,
      },
    })
  }
}

export const SET_DATE_RANGE = 'SUBSCRIPTIONS/SET_DATE_RANGE'

export function setDateRange(dateString) {
  return dispatch => {
    dispatch({
      type: SET_DATE_RANGE,
      payload: {
        dateFrom: dateString[0],
        dateTo: dateString[1],
      },
    })
  }
}

export const RESET_FILTERS = 'SUBSCRIPTIONS/RESET_FILTERS'

export function resetFilters() {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
    })
  }
}

export const APPLY_FILTERS = 'SUBSCRIPTIONS/APPLY_FILTERS'

export function applyFilters() {
  return dispatch => {
    dispatch({
      type: APPLY_FILTERS,
    })
  }
}

export const COPY_SUBSCRIPTION_REQUEST = 'SUBSCRIPTIONS/COPY_SUBSCRIPTION_REQUEST'
export const COPY_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS/COPY_SUBSCRIPTION_SUCCESS'

export function copySubscription(id) {
  return dispatch => {
    dispatch({
      types: [COPY_SUBSCRIPTION_REQUEST, COPY_SUBSCRIPTION_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/subscription/copy/${id}`,
        },
        id,
      },
    })
  }
}

export const DELETE_SUBSCRIPTION_REQUEST = 'SUBSCRIPTIONS/DELETE_SUBSCRIPTION_REQUEST'
export const DELETE_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS/DELETE_SUBSCRIPTION_SUCCESS'

export function deleteSubscription(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_SUBSCRIPTION_REQUEST, DELETE_SUBSCRIPTION_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/subscription/delete/${id}`,
        },
        id,
      },
    })
  }
}

export const OPEN_SUBSCRIPTION_FORM = 'SUBSCRIPTIONS/OPEN_SUBSCRIPTION_FORM'

export function openSubscriptionForm() {
  return dispatch => {
    dispatch({
      type: OPEN_SUBSCRIPTION_FORM,
    })
  }
}

export const CLOSE_SUBSCRIPTION_FORM = 'SUBSCRIPTIONS/CLOSE_SUBSCRIPTION_FORM'

export function closeSubscriptionForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_SUBSCRIPTION_FORM,
    })
  }
}

export const OPEN_EDIT_SUBSCRIPTION_FORM = 'SUBSCRIPTIONS/OPEN_EDIT_SUBSCRIPTION_FORM'

export function openEditSubscriptionForm(id) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_SUBSCRIPTION_FORM,
      payload: {
        id,
      },
    })
  }
}

// SUBSCRIPTION FORM START

export const SET_FIELD_FOR_SUBSCRIPTION = 'SUBSCRIPTIONS/SET_FIELD_FOR_SUBSCRIPTION'

export function setFieldForSubscription(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_SUBSCRIPTION,
      payload: {
        field,
        value,
      },
    })
  }
}

export const UPDATE_CATEGORIES = 'SUBSCRIPTIONS/UPDATE_CATEGORIES'

export function updateCategories(value) {
  return dispatch => {
    dispatch({
      type: UPDATE_CATEGORIES,
      payload: {
        value,
      },
    })
  }
}

export const SET_PRICE_FOR_CATEGORY = 'SUBSCRIPTIONS/SET_PRICE_FOR_CATEGORY'

export function setPriceForCategory(id, value) {
  return dispatch => {
    dispatch({
      type: SET_PRICE_FOR_CATEGORY,
      payload: {
        id,
        value,
      },
    })
  }
}

export const UPDATE_PLACES = 'SUBSCRIPTIONS/UPDATE_PLACES'

export function updatePlaces(value) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLACES,
      payload: {
        value,
      },
    })
  }
}

export const SET_NUM_VISITS_FOR_PLACE = 'SUBSCRIPTIONS/SET_NUM_VISITS_FOR_PLACE'

export function setNumVisitsForPlace(id, value) {
  return dispatch => {
    dispatch({
      type: SET_NUM_VISITS_FOR_PLACE,
      payload: {
        id,
        value,
      },
    })
  }
}

export const CREATE_SUBSCRIPTION_REQUEST = 'SUBSCRIPTIONS/CREATE_SUBSCRIPTION_REQUEST'
export const CREATE_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS/CREATE_SUBSCRIPTION_SUCCESS'

export function createSubscription(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_SUBSCRIPTION_REQUEST, CREATE_SUBSCRIPTION_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/subscription/create`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

export const UPDATE_SUBSCRIPTION_REQUEST = 'SUBSCRIPTIONS/UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS/UPDATE_SUBSCRIPTION_SUCCESS'

export function updateSubscription(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_SUBSCRIPTION_REQUEST, UPDATE_SUBSCRIPTION_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/subscription/update/${id}`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

// SUBSCRIPTION FORM END