export const CHANGE_SHOW_ALL_FIELDS = 'SETTINGS/CHANGE_SHOW_ALL_FIELDS'

export function changeShowAllFields(value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SHOW_ALL_FIELDS,
      payload: {
        value,
      },
    })
  }
}

export const UPDATE_CREATE_MODE = 'SETTINGS/UPDATE_CREATE_MODE'

export function updateCreateMode(value) {
  return dispatch => {
    dispatch({
      type: UPDATE_CREATE_MODE,
      payload: {
        value,
      },
    })
  }
}

export const UPDATE_EVENTS_ACTIVE_BRANCH =
  'SETTINGS/UPDATE_EVENTS_ACTIVE_BRANCH'

export function updateEventsActiveBranch(value) {
  return dispatch => {
    dispatch({
      type: UPDATE_EVENTS_ACTIVE_BRANCH,
      payload: {
        value,
      },
    })
  }
}
