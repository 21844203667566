import produce from 'immer'

import {
  SET_DATE_RANGE,
  SET_FIELD_FOR_REPORT,
  RESET_FILTERS,
} from '../actions/ReportActions'

const initialState = {
  filterForm: {
    date_from: null,
    date_to: null,
    place: [],
    order_status: [],
    event_type: [],
    event: [],
    category: [],
    event_date_from: null,
    event_date_to: null,
    price_from: null,
    price_to: null,
    report_type: 'full',
    is_online: [],
  },
  filterForm2: {
    date_from: null,
    date_to: null,
    place: [],
    order_status: [],
  },
  subscriptionsFilterForm: {
    date_from: null,
    date_to: null,
    place: [],
    order_status: [],
    report_type: 'sales',
  },
  certificatesFilterForm: {
    date_from: null,
    date_to: null,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATE_RANGE:
      return produce(state, draftState => {
        if (action.payload.type === 'sale_date') {
          draftState[action.payload.form].date_from = action.payload.date_from
          draftState[action.payload.form].date_to = action.payload.date_to
        } else {
          draftState[action.payload.form].event_date_from = action.payload.date_from
          draftState[action.payload.form].event_date_to = action.payload.date_to
        }

      })
    case SET_FIELD_FOR_REPORT:
      return produce(state, draftState => {
        draftState[action.payload.form][action.payload.field] = action.payload.value
      })
    case RESET_FILTERS:
      return produce(state, draftState => {
        draftState[action.payload.form] = initialState[action.payload.form]
      })
    default:
      return state
  }
}