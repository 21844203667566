import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  InputNumber,
  Table,
  Popconfirm,
  message,
} from 'antd'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'

import {
  getPlacesList,
} from '../../../redux/actions/CommonActions'

import {
  setFileldForCashier,
  getTicketsList,
  setCategoriesForTicket,
  updateCategoryNumber,
  sendManualSales,
} from '../../../redux/actions/CashierActions'

// import Unloading from './Components/UnloadingComponent'

const { Option } = Select

class Cashier extends PureComponent {
  componentDidMount() {
    this.props.getPlacesList()
  }

  componentDidUpdate() {
    const { manualForm } = this.props.cashier
    if (manualForm.message) {
      message.success(manualForm.message)
      this.props.setFileldForCashier('message', '')
    }
  }

  render() {
    const { manualForm } = this.props.cashier

    const categoriesDataSource = manualForm.categories
      .map(item => ({
        key: item.category_id,
        category_name: item.category_name,
        price: item.price,
        number: (
          <InputNumber
            min={0}
            defaultValue={item.number}
            onChange={value =>
              this.props.updateCategoryNumber(item.category_id, value)
            }
          />
        ),
        sum: item.sum,
      }))

    const columns = [
      {
        title: 'Категория',
        dataIndex: 'category_name',
        key: 'category_name',
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Количество',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
      },
    ]

    let sum = 0
    let count = 0

    manualForm.categories.forEach(item => {
      sum += item.sum
      count += item.number
    })

    return (
      <div>
        <Helmet>
          <title>Кассир</title>
        </Helmet>

        <h1>
          Информация о продажах
        </h1>

        <Row gutter={16} className="Activity__settings">
          <Col span={9}>

            <DatePicker
              allowClear={false}
              placeholder="Дата"
              format={'DD.MM.YYYY'}
              style={{ width: '100%', marginBottom: '1rem' }}
              onChange={(value, dateString) =>
                this.props.setFileldForCashier(
                  'date',
                  moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                )
              }
              value={
                manualForm.date
                  ? moment(manualForm.date, 'YYYY-MM-DD')
                  : null
              }
            />

            <Select
              placeholder="Выберите место"
              filterOption={false}
              style={{ width: '100%', marginBottom: '1rem' }}
              value={manualForm.place_id}
              onChange={value => {
                this.props.setFileldForCashier('place_id', value)
                this.props.setFileldForCashier('ticket_id', undefined)
                this.props.getTicketsList(1, { place: value })
              }}
            >
              {this.props.common.places.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>

            {(manualForm.place_id ? (
              <Select
                placeholder="Выберите билет"
                filterOption={false}
                style={{ width: '100%', marginBottom: '1rem' }}
                value={manualForm.ticket_id}
                onChange={value => {
                  this.props.setFileldForCashier('ticket_id', value)
                  this.props.setCategoriesForTicket(value)
                }}
                loading={manualForm.isLoadingTickets}
              >
                {manualForm.tickets.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            ) : null
            )}

            {manualForm.ticket_id && (
              <>
                <Table
                  dataSource={categoriesDataSource}
                  columns={columns}
                  pagination={false}
                />

                <br />

                <p>
                  <b>
                    Билетов: {count}, Сумма: {sum}₽
                  </b>
                </p>

                <Popconfirm
                  title="Вы уверены в корректности данных?"
                  disabled={count === 0 || !manualForm.date}
                  onConfirm={() => this.props.sendManualSales({
                    ticket_id: manualForm.ticket_id,
                    date: manualForm.date,
                    categories: manualForm.categories.map(item => ({
                      category_id: parseInt(item.category_id),
                      number: parseInt(item.number),
                    }))
                  })}

                  okText="Да"
                  cancelText="Нет"
                >
                  <Button
                    type="primary"
                    disabled={count === 0 || !manualForm.date}
                    loading={manualForm.isSending}
                  >
                    Сохранить
                  </Button>
                </Popconfirm>
              </>
            )}

          </Col>
        </Row>

        {/*<Unloading />*/}

      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    common: store.common,
    cashier: store.cashier,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPlacesList: () => dispatch(getPlacesList()),
    setFileldForCashier: (field, value) => dispatch(setFileldForCashier(field, value)),
    getTicketsList: (pageNumber = 1, filterForm = null) => dispatch(getTicketsList(pageNumber, filterForm)),
    setCategoriesForTicket: (ticket_id) => dispatch(setCategoriesForTicket(ticket_id)),
    updateCategoryNumber: (index, number) =>
      dispatch(updateCategoryNumber(index, number)),
    sendManualSales: (formObj) => dispatch(sendManualSales(formObj)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cashier)