export const CERTIFICATES_LOAD_REQUEST = 'CERTIFICATES/CERTIFICATES_LOAD_REQUEST'
export const CERTIFICATES_LOAD_SUCCESS = 'CERTIFICATES/CERTIFICATES_LOAD_SUCCESS'

export function getCertificatesList(pageNumber = 1, sort = null, filterForm = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    if (filterForm) {
      additionalParams.available_for_sale = filterForm.available_for_sale
    }

    dispatch({
      types: [CERTIFICATES_LOAD_REQUEST, CERTIFICATES_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/certificate/get-certificates-list',
          params: {
            page: pageNumber,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const SET_AVAILABLE_FOR_SALE = 'CERTIFICATES/SET_AVAILABLE_FOR_SALE'

export function setAvailableForSale(value) {
  return dispatch => {
    dispatch({
      type: SET_AVAILABLE_FOR_SALE,
      payload: {
        value,
      },
    })
  }
}

export const RESET_FILTERS = 'CERTIFICATES/RESET_FILTERS'

export function resetFilters() {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
    })
  }
}

export const APPLY_FILTERS = 'CERTIFICATES/APPLY_FILTERS'

export function applyFilters() {
  return dispatch => {
    dispatch({
      type: APPLY_FILTERS,
    })
  }
}

export const COPY_CERTIFICATE_REQUEST = 'CERTIFICATES/COPY_CERTIFICATE_REQUEST'
export const COPY_CERTIFICATE_SUCCESS = 'CERTIFICATES/COPY_CERTIFICATE_SUCCESS'

export function copyCertificate(id) {
  return dispatch => {
    dispatch({
      types: [COPY_CERTIFICATE_REQUEST, COPY_CERTIFICATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/certificate/copy/${id}`,
        },
        id,
      },
    })
  }
}

export const DELETE_CERTIFICATE_REQUEST = 'CERTIFICATES/DELETE_CERTIFICATE_REQUEST'
export const DELETE_CERTIFICATE_SUCCESS = 'CERTIFICATES/DELETE_CERTIFICATE_SUCCESS'

export function deleteCertificate(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_CERTIFICATE_REQUEST, DELETE_CERTIFICATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/certificate/delete/${id}`,
        },
        id,
      },
    })
  }
}


export const OPEN_CERTIFICATE_FORM = 'CERTIFICATES/OPEN_CERTIFICATE_FORM'

export function openCertificateForm() {
  return dispatch => {
    dispatch({
      type: OPEN_CERTIFICATE_FORM,
    })
  }
}

export const CLOSE_CERTIFICATE_FORM = 'CERTIFICATES/CLOSE_CERTIFICATE_FORM'

export function closeCertificateForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_CERTIFICATE_FORM,
    })
  }
}

export const OPEN_EDIT_CERTIFICATE_FORM = 'CERTIFICATES/OPEN_EDIT_CERTIFICATE_FORM'

export function openEditCertificateForm(id) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_CERTIFICATE_FORM,
      payload: {
        id,
      },
    })
  }
}

// CERTIFICATE FORM START

export const SET_FIELD_FOR_CERTIFICATE = 'CERTIFICATES/SET_FIELD_FOR_CERTIFICATE'

export function setFieldForCertificate(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_CERTIFICATE,
      payload: {
        field,
        value,
      },
    })
  }
}

export const CREATE_CERTIFICATE_REQUEST = 'CERTIFICATES/CREATE_CERTIFICATE_REQUEST'
export const CREATE_CERTIFICATE_SUCCESS = 'CERTIFICATES/CREATE_CERTIFICATE_SUCCESS'

export function createCertificate(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_CERTIFICATE_REQUEST, CREATE_CERTIFICATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/certificate/create`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

export const UPDATE_CERTIFICATE_REQUEST = 'CERTIFICATES/UPDATE_CERTIFICATE_REQUEST'
export const UPDATE_CERTIFICATE_SUCCESS = 'CERTIFICATES/UPDATE_CERTIFICATE_SUCCESS'

export function updateCertificate(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_CERTIFICATE_REQUEST, UPDATE_CERTIFICATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/certificate/update/${id}`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

// CERTIFICATE FORM END