import produce from 'immer'

import {
  SET_AVAILABLE_FOR_SALE,
  RESET_FILTERS,
  APPLY_FILTERS,
  CERTIFICATES_LOAD_REQUEST,
  CERTIFICATES_LOAD_SUCCESS,
  COPY_CERTIFICATE_REQUEST,
  COPY_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_REQUEST,
  DELETE_CERTIFICATE_SUCCESS,
  OPEN_CERTIFICATE_FORM,
  CLOSE_CERTIFICATE_FORM,
  OPEN_EDIT_CERTIFICATE_FORM,
  SET_FIELD_FOR_CERTIFICATE,
  CREATE_CERTIFICATE_REQUEST,
  CREATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_REQUEST,
  UPDATE_CERTIFICATE_SUCCESS,
} from '../actions/CertificatesActions'

const initialState = {
  isLoadingCertificates: false,
  certificates: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc',
  },
  filter: {
    available_for_sale: '1',
  },

  filterForm: {
    available_for_sale: '1',
  },

  certificateForm: {
    id: null,
    price: null,
    is_available_for_sale: 1,

    booked_certificates: 0,
    certificates_sold: 0,

    isOpen: false,

    isSending: false,
  },

  isDeletingCertificate: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CERTIFICATES_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingCertificates = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.certificates = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case CERTIFICATES_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingCertificates = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.certificates = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.certificates = draftState.certificates.concat(action.payload.data)
        }
      })
    case APPLY_FILTERS:
      return produce(state, draftState => {
        draftState.filter = draftState.filterForm
      })
    case RESET_FILTERS:
      return produce(state, draftState => {
        draftState.filter = initialState.filter
        draftState.filterForm = initialState.filterForm
      })
    case SET_AVAILABLE_FOR_SALE:
      return produce(state, draftState => {
        draftState.filterForm.available_for_sale = action.payload.value
      })
    case COPY_CERTIFICATE_REQUEST:
      return produce(state, draftState => {
        draftState.animation = true
      })
    case COPY_CERTIFICATE_SUCCESS:
      return produce(state, draftState => {
        const { data } = action.payload
        draftState.certificates.unshift(data)
      })
    case DELETE_CERTIFICATE_SUCCESS:
      return produce(state, draftState => {
        draftState.isDeletingCertificate = false
      })
    case DELETE_CERTIFICATE_REQUEST:
      return produce(state, draftState => {
        draftState.isDeletingCertificate = true
        draftState.animation = true

        draftState.certificates.splice(
          draftState.certificates.findIndex(item => item.id === action.payload.id),
          1
        )
      })
    case CLOSE_CERTIFICATE_FORM:
      return produce(state, draftState => {
        draftState.certificateForm.isOpen = false
      })
    case OPEN_CERTIFICATE_FORM:
      return produce(state, draftState => {
        draftState.certificateForm = {
          ...initialState.certificateForm,
          isOpen: true,
        }
      })
    case OPEN_EDIT_CERTIFICATE_FORM:
      return produce(state, draftState => {
        let certificate = draftState.certificates.find(
          item => item.id === action.payload.id
        )

        draftState.certificateForm.id = certificate.id
        draftState.certificateForm.price = certificate.price
        draftState.certificateForm.is_available_for_sale =
          certificate.is_available_for_sale

        draftState.certificateForm.booked_certificates = certificate.booked_certificates
        draftState.certificateForm.certificates_sold = certificate.certificates_sold

        draftState.certificateForm.isOpen = true
      })
    case SET_FIELD_FOR_CERTIFICATE:
      return produce(state, draftState => {
        draftState.certificateForm[action.payload.field] = action.payload.value
      })
    case CREATE_CERTIFICATE_SUCCESS:
      return produce(state, draftState => {
        draftState.certificateForm.isSending = false
        draftState.certificateForm.isOpen = false
        const { data } = action.payload
        draftState.certificates.unshift(data)
      })
    case CREATE_CERTIFICATE_REQUEST:
      return produce(state, draftState => {
        draftState.certificateForm.isSending = true
        draftState.animation = true
      })
    case UPDATE_CERTIFICATE_SUCCESS:
      return produce(state, draftState => {
        draftState.certificateForm.isSending = false
        draftState.certificateForm.isOpen = false

        let certificateIndex = draftState.certificates.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.certificates[certificateIndex] = action.payload.data
      })
    case UPDATE_CERTIFICATE_REQUEST:
      return produce(state, draftState => {
        draftState.certificateForm.isSending = true
      })
    default:
      return state
  }
}