import produce from 'immer'

import {
  SET_AVAILABLE_FOR_SALE,
  SET_DATE_RANGE,
  RESET_FILTERS,
  APPLY_FILTERS,
  TICKETS_LOAD_REQUEST,
  TICKETS_LOAD_SUCCESS,
  COPY_TICKET_REQUEST,
  COPY_TICKET_SUCCESS,
  DELETE_TICKET_REQUEST,
  DELETE_TICKET_SUCCESS,
  OPEN_TICKET_FORM,
  CLOSE_TICKET_FORM,
  SET_FIELD_FOR_TICKET,
  UPDATE_CATEGORIES,
  SET_PRICE_FOR_CATEGORY,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  OPEN_EDIT_TICKET_FORM,
  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_SUCCESS,
  SET_PLACE,
} from '../actions/TicketsActions'

const initialState = {
  isLoadingTickets: false,
  tickets: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc',
  },
  filter: {
    dateFrom: null,
    dateTo: null,
    available_for_sale: '1',
    place: [],
  },

  filterForm: {
    dateFrom: null,
    dateTo: null,
    available_for_sale: '1',
    place: [],
  },

  ticketForm: {
    id: null,
    branch_id: null,
    place_id: null,
    event_id: null,
    excursion_id: null,
    title: null,
    title_en: null,
    receipt_title: null,
    age_limit: null,
    sales_limit: 0,
    order_limit: null,
    comment: null,
    comment_en: null,
    color: null,
    is_available_for_sale: 0,
    is_fixed: 1,
    is_date: 0,
    date_valid_from: null,
    date_valid_to: null,
    is_pushka: 0,
    is_only_pushka: 0,
    pro_culture_event_id: null,
    pro_culture_place_id: null,
    is_online: 0,
    is_offline: 0,
    is_link_only_access: 0,
    link_access_code: null,
    category_ids: [],
    categories: [],

    booked_tickets: 0,
    tickets_sold: 0,

    isOpen: false,

    isSending: false,
  },

  isDeletingTicket: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case TICKETS_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingTickets = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.tickets = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case TICKETS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingTickets = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.tickets = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.tickets = draftState.tickets.concat(action.payload.data)
        }
      })
    case APPLY_FILTERS:
      return produce(state, draftState => {
        draftState.filter = draftState.filterForm
      })
    case RESET_FILTERS:
      return produce(state, draftState => {
        draftState.filter = initialState.filter
        draftState.filterForm = initialState.filterForm
      })
    case SET_DATE_RANGE:
      return produce(state, draftState => {
        draftState.filterForm.dateFrom = action.payload.dateFrom
        draftState.filterForm.dateTo = action.payload.dateTo
      })
    case SET_PLACE:
      return produce(state, draftState => {
        draftState.filterForm.place = action.payload.place
      })
    case SET_AVAILABLE_FOR_SALE:
      return produce(state, draftState => {
        draftState.filterForm.available_for_sale = action.payload.value
      })
    case COPY_TICKET_REQUEST:
      return produce(state, draftState => {
        draftState.animation = true
      })
    case COPY_TICKET_SUCCESS:
      return produce(state, draftState => {
        const { data } = action.payload
        draftState.tickets.unshift(data)
      })
    case DELETE_TICKET_SUCCESS:
      return produce(state, draftState => {
        draftState.isDeletingTicket = false
      })
    case DELETE_TICKET_REQUEST:
      return produce(state, draftState => {
        draftState.isDeletingTicket = true
        draftState.animation = true

        draftState.tickets.splice(
          draftState.tickets.findIndex(item => item.id === action.payload.id),
          1
        )
      })
    case CLOSE_TICKET_FORM:
      return produce(state, draftState => {
        draftState.ticketForm.isOpen = false
      })
    case OPEN_TICKET_FORM:
      return produce(state, draftState => {
        draftState.ticketForm = {
          ...initialState.ticketForm,
          isOpen: true,
        }
      })
    case SET_FIELD_FOR_TICKET:
      return produce(state, draftState => {
        draftState.ticketForm[action.payload.field] = action.payload.value
      })
    case UPDATE_CATEGORIES:
      return produce(state, draftState => {
        let newCategoriesList = []
        let currentCategoriesIds = []

        draftState.ticketForm.categories.forEach(item => {
          if (action.payload.value.includes(item.category_id)) {
            newCategoriesList.push(item)
          }
          currentCategoriesIds.push(item.category_id)
        })

        action.payload.value.forEach(id => {
          if (!currentCategoriesIds.includes(id)) {
            newCategoriesList.push({
              category_id: id,
              price: 0,
            })
          }
        })

        draftState.ticketForm.categories = newCategoriesList
      })
    case SET_PRICE_FOR_CATEGORY:
      return produce(state, draftState => {
        draftState.ticketForm.categories.forEach((item, index) => {
          if (parseInt(item.category_id) === parseInt(action.payload.id)) {
            draftState.ticketForm.categories[index].price = action.payload.value
          }
        })
      })
    case CREATE_TICKET_SUCCESS:
      return produce(state, draftState => {
        draftState.ticketForm.isSending = false
        draftState.ticketForm.isOpen = false
        const { data } = action.payload
        draftState.tickets.unshift(data)
      })
    case CREATE_TICKET_REQUEST:
      return produce(state, draftState => {
        draftState.ticketForm.isSending = true
        draftState.animation = true
      })
    case OPEN_EDIT_TICKET_FORM:
      return produce(state, draftState => {
        let ticket = draftState.tickets.find(
          item => item.id === action.payload.id
        )

        draftState.ticketForm.id = ticket.id
        draftState.ticketForm.branch_id = ticket.branch.branch_id
        draftState.ticketForm.place_id = ticket.place.place_id
        draftState.ticketForm.event_id = ticket.event.event_id
        draftState.ticketForm.excursion_id = ticket.excursion.excursion_id
        draftState.ticketForm.title = ticket.title
        draftState.ticketForm.title_en = ticket.title_en
        draftState.ticketForm.receipt_title = ticket.receipt_title
        draftState.ticketForm.age_limit = ticket.age_limit
        draftState.ticketForm.sales_limit = ticket.sales_limit
        draftState.ticketForm.order_limit = ticket.order_limit
        draftState.ticketForm.comment = ticket.comment
        draftState.ticketForm.comment_en = ticket.comment_en
        draftState.ticketForm.color = ticket.color
        draftState.ticketForm.is_available_for_sale =
          ticket.is_available_for_sale
        draftState.ticketForm.is_fixed = ticket.is_fixed
        draftState.ticketForm.is_date = ticket.date_valid_from ? 1 : 0
        draftState.ticketForm.date_valid_from = ticket.date_valid_from
        draftState.ticketForm.date_valid_to = ticket.date_valid_to
        draftState.ticketForm.is_pushka = ticket.is_pushka
        draftState.ticketForm.is_only_pushka = ticket.is_only_pushka
        draftState.ticketForm.pro_culture_event_id = ticket.pro_culture_event_id
        draftState.ticketForm.pro_culture_place_id = ticket.pro_culture_place_id
        draftState.ticketForm.is_online = ticket.is_online
        draftState.ticketForm.is_offline = ticket.is_offline
        draftState.ticketForm.category_ids = ticket.categories
          ? ticket.categories.map(item => item.category_id + '')
          : []
        draftState.ticketForm.categories = ticket.categories
          ? ticket.categories.map(item => ({
            category_id: item.category_id + '',
            price: item.price,
          }))
          : []
        draftState.ticketForm.booked_tickets = ticket.booked_tickets
        draftState.ticketForm.tickets_sold = ticket.tickets_sold
        draftState.ticketForm.is_link_only_access = ticket.is_link_only_access
        draftState.ticketForm.link_access_code = ticket.link_access_code

        draftState.ticketForm.isOpen = true
      })
    case UPDATE_TICKET_SUCCESS:
      return produce(state, draftState => {
        draftState.ticketForm.isSending = false
        draftState.ticketForm.isOpen = false

        let ticketIndex = draftState.tickets.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.tickets[ticketIndex] = action.payload.data
      })
    case UPDATE_TICKET_REQUEST:
      return produce(state, draftState => {
        draftState.ticketForm.isSending = true
      })

    default:
      return state
  }
}
